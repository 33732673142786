import React, { useState } from "react";

import { IconButton, Tooltip } from "@puzzle/ui";
import { HollowCircle, Confirmed } from "@puzzle/icons";

import Analytics from "lib/analytics/analytics";
import { OnboardingStepStatus } from "graphql/types";
import { Button, Text } from "ve";
import { Box } from "@puzzle/ve";
import { colors } from "@puzzle/theme";

import {
  rootStyle,
  mainContentsStyle,
  checkIconButtonStyle,
  skipContainerStyle,
} from "./taskTile.css";
import { LaunchpadTask, LaunchpadStepAction } from "./analytics";

const StatusIcon = ({
  status,
  disableOnHover,
}: {
  status: OnboardingStepStatus | null | undefined;
  disableOnHover?: boolean;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const getIcon = () => {
    if (!disableOnHover && isHovered) {
      return <Confirmed />;
    }

    switch (status) {
      case OnboardingStepStatus.Completed:
        return <Confirmed />;
      case OnboardingStepStatus.Skipped:
        return <Confirmed color={colors.neutral300} />;
      // BE defaults to null, handle NotCompleted or undefined as well
      default:
        return <HollowCircle />;
    }
  };

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="inline-flex"
    >
      {getIcon()}
    </div>
  );
};

export const TaskTile = ({
  heading,
  subheading,
  stepStatus,
  onClick,
  onComplete,
  onSkip,
  analyticsTask,
  completeOnClick = true,
  completeTooltip,
  fullSize = true,
  disabled = false,
}: {
  heading: string;
  subheading: string;
  stepStatus: OnboardingStepStatus | null | undefined;
  onClick: () => void;
  onComplete?: () => void;
  onSkip?: () => void;
  analyticsTask: LaunchpadTask;
  completeOnClick?: boolean;
  completeTooltip?: string;
  fullSize?: boolean;
  disabled?: boolean;
}) => {
  const skipable =
    onSkip &&
    stepStatus !== OnboardingStepStatus.Completed &&
    stepStatus !== OnboardingStepStatus.Skipped;

  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      return;
    }

    if (e.target instanceof HTMLElement || e.target instanceof SVGElement) {
      // Only trigger if we didn't click the completion button or skip button
      if (
        !e.target.closest(`.${checkIconButtonStyle}`) &&
        !e.target.closest(`.${skipContainerStyle}`)
      ) {
        if (completeOnClick) {
          Analytics.launchpadTaskActioned({
            Action: LaunchpadStepAction.Completed,
            Task: analyticsTask,
          });
          onComplete?.();
        }
        onClick?.();
      } else if (e.target.closest(`.${checkIconButtonStyle}`)) {
        Analytics.launchpadTaskActioned({
          Action: LaunchpadStepAction.Completed,
          Task: analyticsTask,
        });
        onComplete?.();
      } else if (e.target.closest(`.${skipContainerStyle}`)) {
        Analytics.launchpadTaskActioned({
          Action: LaunchpadStepAction.Skipped,
          Task: analyticsTask,
        });
        onSkip?.();
      }
    }
  };

  return (
    <Tooltip
      content={
        disabled &&
        "Hang tight, this task will be available as soon as your data has been ingested."
      }
      side="left"
    >
      <div>
        <Box
          className={rootStyle}
          css={{ opacity: disabled ? 0.5 : 1, cursor: disabled ? "not-allowed" : "pointer" }}
          onClick={handleClick}
          tabIndex={0}
          role="button"
        >
          <Tooltip content={completeTooltip} side="top">
            <IconButton className={checkIconButtonStyle} size="fit-content" disabled={disabled}>
              <StatusIcon status={stepStatus} disableOnHover={disabled} />
              <span className="sr-only">Complete task</span>
            </IconButton>
          </Tooltip>

          <div className={mainContentsStyle}>
            <Text variant="headingS" weight="bold" color="white">
              {heading}
            </Text>
            {fullSize && (
              <Text variant="bodyS" color="white70">
                {subheading}
              </Text>
            )}
          </div>

          {skipable && (
            <div className={skipContainerStyle}>
              <Button variant="minimal" size="mini">
                <Text variant="bodyXS" color="white50">
                  Skip
                </Text>
              </Button>
            </div>
          )}
        </Box>
      </div>
    </Tooltip>
  );
};
