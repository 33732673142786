import React from "react";
import { Text } from "@puzzle/ui";
import { styled } from "@puzzle/theme";
import { SynchronizingThin } from "components/common/Synchronizing";
import { Box } from "@puzzle/ve";
import { S } from "@puzzle/theme";

const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 20,
  py: 128,
});

const Content = styled("p", {
  margin: 0,
  size: 14,
  color: "$neutral300",
});

const IngestingReport = () => {
  return (
    <Wrapper>
      <Box css={{ display: "flex", alignItems: "center", gap: S["1h"] }}>
        <Text variant="heading2">Reports are building</Text>
        <SynchronizingThin />
      </Box>
      <Content>Please wait, this action may take a few minutes.</Content>
      <Content>We will email you when your data is processed.</Content>
    </Wrapper>
  );
};

export default IngestingReport;
