import React from "react";
import { colors } from "@puzzle/theme";
import Icon, { IconProps } from "./Icon";

export default function HollowCircle({
  className,
  rotate,
  size = 16,
  viewBox = "0 0 16 16",
  color = colors.neutral400,
  ...props
}: IconProps) {
  return (
    <Icon className={className} rotate={rotate} viewBox={viewBox} size={size} {...props}>
      <circle fill="none" cx="8" cy="8" r="7.5" stroke={color}></circle>
    </Icon>
  );
}
