import { colors, styled } from "@puzzle/theme";

export const CollapseButton = styled("button", {
  unset: "all",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: "$0h $0h $0h $1",
  gap: "$0h",
  background: "$mauve700",
  border: `1px solid ${colors.neutral700}`,
  borderRadius: "4px",
  color: "$neutral300",

  position: "absolute",
  top: 0,
  left: "50%",
  transform: `translate(-50%, -50%)`,
  cursor: "pointer",
});
