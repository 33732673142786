import React from "react";
import Image from "next/image";

import links from "lib/links";
import Analytics from "lib/analytics/analytics";

import { Button, Text } from "ve";
import { S } from "@puzzle/theme";
import { TaskList } from "./TaskList";
import { useLaunchpadStore } from "./launchpadStore";

import rocket from "public/images/launchpad/rocket.svg?url";

import {
  rootStyle,
  leftSectionStyle,
  rightSectionStyle,
  buttonContainerStyle,
  headingStyle,
  chevronDownStyle,
  taskListWrapperStyle,
} from "./launchpadBanner.css";
import { TASKLIST_HEADING, TASKLIST_DESCRIPTION } from "./constants";
import { useOnboardingProgress } from "./useOnboardingProgress";
import { LaunchpadSource } from "./analytics";
import { ChevronDown } from "@puzzle/icons";

export const LaunchpadBanner = () => {
  const { showScheduleOnboardingCall, dismissLaunchpadBanner } = useOnboardingProgress();
  const { showLaunchpadOverlay } = useLaunchpadStore();

  return (
    <div className={rootStyle}>
      <div className={leftSectionStyle}>
        <div className={headingStyle}>
          <Image src={rocket} alt="" width={32} height={32} />
          <Text variant="heading2" color="white" weight="bold">
            {TASKLIST_HEADING}
          </Text>
        </div>
        <Text variant="headingS" color="neutral300" style={{ lineHeight: "26px" }}>
          <span dangerouslySetInnerHTML={{ __html: TASKLIST_DESCRIPTION }} />
        </Text>
        <div className={buttonContainerStyle}>
          <Button
            onClick={() => {
              showLaunchpadOverlay();
              dismissLaunchpadBanner();
            }}
          >
            Get Started
          </Button>
          {showScheduleOnboardingCall && (
            <Button
              variant="secondary"
              onClick={() => {
                window.open(links.freeConciergeOnboarding, "_blank");
                Analytics.onboardingCallCtaClicked({
                  Source: LaunchpadSource.DashboardLaunchpadBanner,
                });
              }}
            >
              Schedule onboarding call
            </Button>
          )}
        </div>
      </div>
      <div className={rightSectionStyle}>
        <Button
          variant="minimal"
          size="small"
          style={{ alignSelf: "flex-end", marginTop: S.$3, marginBottom: S["$0h"] }}
          onClick={dismissLaunchpadBanner}
        >
          Setup later
        </Button>
        <div className={taskListWrapperStyle}>
          <TaskList fullSize={false} />
        </div>
        <ChevronDown
          className={chevronDownStyle}
          size={20}
          onClick={() => {
            showLaunchpadOverlay();
          }}
        />
      </div>
    </div>
  );
};
